<template>
  <div class="p-4 rounded-md" :class="statusClasses">
    <div class="flex items-start gap-3">
      <ciam-icon :name="statusIcon" :class="statusIconClass + ' h-5 w-5 mt-0.5 flex-shrink-0'"></ciam-icon>
      <div>
        <h3 class="font-medium mb-1" :class="statusTitleClass">{{ statusTitle }}</h3>
        <p class="text-sm text-gray-700">
          {{ statusMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceStatusAlert',
  props: {
    status: {
      type: String,
      required: true,
      validator: value => ['PENDING', 'ASAP', 'RUNNING'].includes(value)
    },
    maintenanceWindowTime: {
      type: String,
      default: 'Next maintenance window'
    }
  },
  computed: {
    statusClasses() {
      switch (this.status) {
        case 'PENDING':
          return 'bg-blue-50 border border-blue-200';
        case 'ASAP':
          return 'bg-blue-50 border border-blue-200';
        case 'RUNNING':
          return 'bg-green-50 border border-green-200';
        default:
          return 'bg-gray-50 border border-gray-200';
      }
    },
    statusIcon() {
      switch (this.status) {
        case 'PENDING':
          return 'fa-calendar-alt';
        case 'ASAP':
          return 'fa-clock';
        case 'RUNNING':
          return 'fa-cogs';
        default:
          return 'fa-info-circle';
      }
    },
    statusIconClass() {
      switch (this.status) {
        case 'PENDING':
          return 'text-blue-500';
        case 'ASAP':
          return 'text-blue-500';
        case 'RUNNING':
          return 'text-green-500';
        default:
          return 'text-gray-500';
      }
    },
    statusTitleClass() {
      switch (this.status) {
        case 'PENDING':
          return 'text-blue-700';
        case 'ASAP':
          return 'text-blue-700';
        case 'RUNNING':
          return 'text-green-700';
        default:
          return 'text-gray-700';
      }
    },
    statusTitle() {
      const key = this.status || 'DEFAULT';
      return this.$t(`deployment.configuration.upgradeOnDemand.maintenanceStatus.title.${key}`);
    },
    statusMessage() {
      const key = this.status || 'DEFAULT';
      if (key === 'PENDING') {
        return this.$t('deployment.configuration.upgradeOnDemand.maintenanceStatus.message.PENDING', { time: this.maintenanceWindowTime });
      }
      return this.$t(`deployment.configuration.upgradeOnDemand.maintenanceStatus.message.${key}`);
    }
  }
};
</script> 