<template>
  <div>
    <Promised :promise="loading">
      <template v-slot:pending>
        <ciam-loader class="py-4"></ciam-loader>
      </template>

      <template v-if="isFree">
        <ciam-alert
          title="Insights feature unavailable"
          :description="getUpsellAlertDescription()"
          :type="AlertStatus.WARNING_HREF"
          :href-text="$t('upsell.email.contact')"
          :href="getUpsellAlertHref()"
        ></ciam-alert>
      </template>

      <template v-else-if="!isFree">
        <ciam-card-content v-if="deployment">
          <div id="realtime-metrics-charts" class="flex flex-col gap-8 m-8">
            <div class="w-max">
              <ciam-select
                :options="realtimeMetrics.ranges"
                v-model="realtimeMetrics.activeRange"
              ></ciam-select>
            </div>
            <div v-for="metric in filteredRealtimeMetricsData" :key="metric.metric">
              <Chart
                :id="metric.metric"
                :title="metric.title"
                :datasets="metric.datasets"
                :labels="metric.labels"
                :chart-type="metric.type"
                :range="realtimeMetrics.activeRange"
              />
            </div>
          </div>
          <div class="metabase-dashboard flex flex-col px-8 pt-8">
            <div class="flex flex-row items-center gap-2">
              <div>
                <ciam-select
                  :options="optionsForYearFilter"
                  v-model="iframes.yearFilterInput"
                ></ciam-select>
              </div>
              <div>
                <ciam-select
                  :options="optionsForMonthFilter"
                  v-model="iframes.monthFilterInput"
                ></ciam-select>
              </div>
              <ciam-button class="primary" @click="generateAllMetabaseIframe()">Search</ciam-button>
            </div>
            <div class="flex flex-row gap-8">
              <div>
                <p class="text-xl font-semibold pt-8 pb-2">Number of users</p>
                <div style="position: relative;">
                  <iframe
                    width="250"
                    height="250"
                    :src="iframes['numberOfUsers'].url"
                    allowtransparency
                  ></iframe>
                  <!-- Hide Metabase banner -->
                  <div style="position: absolute; bottom: 0; left: 0; width: 250px; height: 65px; background: white;" />
                </div>
              </div>
              <div>
                <p class="text-xl font-semibold pt-8 pb-2">Number of realms</p>
                <div style="position: relative;">
                  <iframe
                    width="250"
                    height="250"
                    :src="iframes['numberOfRealms'].url"
                    allowtransparency
                  ></iframe>
                  <!-- Hide Metabase banner -->
                  <div style="position: absolute; bottom: 0; left: 0; width: 250px; height: 65px; background: white;" />
                </div>
              </div>
            </div>
          </div>
          <div class="metabase-dashboard flex flex-col gap-2 px-8">
            <p class="text-xl font-semibold">Number of successful login</p>
            <div style="position: relative;">
              <iframe
                width="100%"
                height="550"
                :src="iframes['numberOfLogins'].url"
                allowtransparency
              ></iframe>
              <!-- Hide Metabase banner -->
              <div style="position: absolute; bottom: 0; left: 0; width: 100%; height: 65px; background: white;" />
            </div>
          </div>
          <div class="metabase-dashboard flex flex-col gap-2 px-8">
            <p class="text-xl font-semibold">Number of failed login</p>
            <div style="position: relative;">
              <iframe
                width="100%"
                height="550"
                :src="iframes['numberOfFailedLogins'].url"
                allowtransparency
              ></iframe>
              <div style="position: absolute; bottom: 0; left: 0; width: 100%; height: 65px; background: white;"></div>
            </div>
          </div>
          <ciam-card-content-line type="full-width" v-if="problem">
            <template v-slot:content>
              <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
            </template>
          </ciam-card-content-line>
        </ciam-card-content>
      </template>
    </Promised>
  </div>
</template>


<script>
import CiamCardContent from '@/components/CiamCardContent';
import CiamCardContentLine from '@/components/CiamCardContentLine';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert';
import CiamSelect from '@/components/CiamSelect.vue';
import { makeQueryablePromise } from '@/util/promise';
import DeploymentService from '@/pages/deployments/DeploymentService';
import UpsellFeatureService from '@/pages/UpsellFeatureService';
import i18n from '@/i18n';
import PlanService from '@/pages/PlanService';
import Chart from './Chart.vue';

export default {
  props: {
    deployment: {
      type: Object,
      required: true,
    },
  },
  name: 'DeploymentDetail',
  components: {
    CiamSelect,
    CiamCardContent,
    CiamCardContentLine,
    CiamAlert,
    Chart,
  },
  data() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Format month as 'MM'

    return {
      isFree: null,
      currentDate,
      currentYear,
      currentMonth,
      loading: makeQueryablePromise(Promise.reject()),
      optionsForYearFilter: [
        { label: '2025', value: '2025' },
        { label: '2024', value: '2024' },
      ],
      optionsForMonthFilter: [
        { label: '01', value: '01' },
        { label: '02', value: '02' },
        { label: '03', value: '03' },
        { label: '04', value: '04' },
        { label: '05', value: '05' },
        { label: '06', value: '06' },
        { label: '07', value: '07' },
        { label: '08', value: '08' },
        { label: '09', value: '09' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
      ],
      AlertStatus: AlertStatus,
      problem: null,
      iframes: {
        yearFilterInput: currentYear,
        monthFilterInput: currentMonth,
        'numberOfLogins': {
          url: '',
          question: 251,
        },
        'numberOfFailedLogins': {
          url: '',
          question: 252,
        },
        'numberOfUsers': {
          url: '',
          question: 254,
        },
        'numberOfRealms': {
          url: '',
          question: 255,
        },
      },
      realtimeMetrics: {
        data: [],
        activeRange: 'hourly',
        ranges: [
          { label: 'Hourly', value: 'hourly' },
          { label: 'Daily', value: 'daily' },
          { label: 'Weekly', value: 'weekly' },
        ],
      },
    };
  },
  watch: {
    'realtimeMetrics.activeRange'(newRange) {
      this.generateRealtimeChart(newRange, 'number-of-requests-by-status-code');
    },
  },
  computed: {
    filteredRealtimeMetricsData() {
      return this.realtimeMetrics.data.filter(metric =>
        metric.datasets && metric.labels && metric.metric && metric.title,
      );
    },
    organizationId() {
      return this.deployment.organizationId;
    },
  },
  mounted() {
    this.getPlan();
    this.generateAllMetabaseIframe();
    this.generateRealtimeChart(this.realtimeMetrics.activeRange, 'number-of-requests-by-status-code');
  },
  methods: {
    getPlan() {
      this.isFree = PlanService.isFree(this.deployment.pricingPlan.name);
    },
    getUpsellAlertHref() {
      return UpsellFeatureService.getEmailHref(i18n.t(`deployment.insights.featureName`));
    },
    getUpsellAlertDescription() {
      return UpsellFeatureService.getEmailDescription(i18n.t(`deployment.insights.featureName`));
    },
    generateAllMetabaseIframe() {
      this.generateMetabaseIframe('numberOfLogins');
      this.generateMetabaseIframe('numberOfFailedLogins');
      this.generateMetabaseIframe('numberOfUsers');
      this.generateMetabaseIframe('numberOfRealms');
    },
    generateMetabaseIframe(type) {
      this.loading = DeploymentService.getMetrics(this.$route.params.id, this.iframes[type].question, this.iframes.yearFilterInput, this.iframes.monthFilterInput)
        .then(insights => {
          this.iframes[type].url = insights.url + '#theme=transparent&bordered=true&titled=false';
        })
        .catch(reason => {
          console.error('Metrics could not be retrieved: ', reason);
          throw reason;
        });
    },
    generateRealtimeChart(range, metric) {
      this.loading = DeploymentService.getRealtimeMetrics(this.$route.params.id, range, metric)
        .then(data => {
          const existingMetric = this.realtimeMetrics.data.find(m => m.metric === data.metadata.metric);
          if (existingMetric) {
            existingMetric.datasets = data.datasets;
            existingMetric.labels = data.labels;
            existingMetric.title = data.metadata.title;
          } else {
            this.realtimeMetrics.data.push({
              datasets: data.datasets,
              labels: data.labels,
              title: data.metadata.title,
              metric: data.metadata.metric,
            });
          }
        })
        .catch(reason => {
          console.error('Realtime metrics could not be retrieved: ', reason);
          throw reason;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  @apply font-medium text-indigo-600 hover:text-indigo-500;
}

.metabase-dashboard {
  width: 100%;
  height: 100%;
}
</style>
