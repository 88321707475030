<template>
  <div class="flex flex-col gap-2">
    <p :title="title" class="text-xl font-semibold">{{ title }}</p>
    <canvas :id="id"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'Chart',
  props: {
    labels: {
      type: Array,
      required: true,
      default: () => [],
    },
    datasets: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: true,
      default: 'Chart',
    },
    id: {
      type: String,
      required: true,
      default: '0',
    },
    chartType: {
      type: String,
      required: true,
      default: 'line',
    },
    range: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.processData(this.labels, this.datasets, this.id, this.chartType);
  },
  methods: {
    generateShade(baseHue, variation) {
      // Creates a smooth gradient effect for better contrast
      let newLightness = Math.min(100, 35 + variation * 5); // Keeps it readable without being too bright
      let newSaturation = Math.max(75, 90 - variation * 9); // Ensures the color remains rich

      return `hsl(${baseHue}, ${newSaturation}%, ${newLightness}%)`;
    },

    getBorderColor(label) {
      const prefix = label.charAt(0);
      const variation = parseInt(label.charAt(2)) || 0; // Extracts last digit for nuance

      // Adjusted base hues for a more aesthetic color scheme
      const baseHues = {
        '2': 145,  // Green
        '4': 200,   // Blue
        '5': 355,   // Red
      };

      if (baseHues[prefix] !== undefined) {
        return this.generateShade(baseHues[prefix], variation);
      }
      return 'hsl(0, 0%, 50%)'; // Default gray for unknown values
    },

    convertToLocalTime(utcString) {
      const date = new Date(utcString);

      if (this.range === 'hourly' || this.range === 'daily') {
        return date.toLocaleTimeString(undefined, {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false, // 24-hour format
        });
      } else if (this.range === 'weekly') {
        return date.toLocaleDateString(undefined, {
          day: '2-digit',
          month: 'short', // "30 Jan"
        });
      }
      return date.toLocaleString(); // Fallback
    },
    processData(labels, datasets, id, chartType) {
      // Convert labels to local time zone
      const localLabels = labels.map(this.convertToLocalTime);

      // Only keep datasets with labels started by 2XX, 4XX or 5XX
      const filteredDatasets = datasets.filter(dataset => /^2|^4|^5/.test(dataset.label));

      const updatedDatasets = filteredDatasets.map(dataset => ({
        ...dataset,
        borderColor: this.getBorderColor(dataset.label),
        borderWidth: 2,
        tension: 0.5,
        backgroundColor: this.getBorderColor(dataset.label),
        fill: false,
      }));

      const chartData = {
        type: chartType,
        data: {
          labels: localLabels,
          datasets: updatedDatasets,
        },
        options: {
          scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
          },
          plugins: {
            tooltip: {
              enabled: true,
            },
          },
        },
      };

      const ctx = document.getElementById(id).getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, chartData);
    },
  },
};
</script>
