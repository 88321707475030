<template>
  <b-modal 
    :active="modelValue" 
    @update:active="$emit('update:model-value', $event)"
    has-modal-card 
    trap-focus 
    :destroy-on-hide="false" 
    aria-role="dialog" 
    aria-modal
  >
    <standard-modal :buttons="buttons" @result="handleResult">
      <template v-slot:header>
        <slot name="header"></slot>
      </template>
      <template v-slot:subtitle v-if="subtitle">
        {{ subtitle }}
      </template>
      <template v-slot:default>
        <slot></slot>
      </template>
    </standard-modal>
  </b-modal>
</template>

<script>
import StandardModal from './StandardModal.vue';

export default {
  name: 'ciam-confirm-modal',
  components: {
    StandardModal
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: 'Cancel'
    }
  },
  computed: {
    buttons() {
      return [
        { text: this.cancelText },
        {
          text: this.confirmText,
          classes: ['is-info', 'is-outlined'],
          returnVal: true,
        },
      ];
    }
  },
  methods: {
    handleResult(confirmed) {
      this.$emit('update:model-value', false);
      this.$emit('result', confirmed);
    }
  }
};
</script> 